import React, { useState, useEffect, useContext } from "react"
import classNames from "classnames"
import axios from "axios"

import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"
import styles from "./utils/layout.module.scss"
import { isBrowser } from "../../services/general"
import { AppContext } from "../../context/AppContext"

const Notification = () => {
  const { state, dispatch } = useContext(AppContext)

  const [banner, setBanner] = useState("")
  const [notificationHidden, setNotificationHidden] = useState(false)

  const hasSeenNotificationMessage = hasSeenNotification()

  useEffect(() => {
    const getBanner = async () => {
      const params = {
        website: process.env.GATSBY_GITHUB_REPO,
        env: process.env.GATSBY_ENV,
      }

      const response = await axios.get(
        process.env.GATSBY_AIRTABLE_BANNER_ENDPOINT,
        { params }
      )

      let actualBannerContent = response?.data?.content || null

      setBanner(actualBannerContent)
    }

    getBanner()
  }, [])

  let notificationEmail = process.env.GATSBY_CLIENT_EMAIL
  useEffect(() => {
    if (isBrowser()) {
      const urlSearchParams = new URLSearchParams(window?.location?.search)

      if (!state?.client && window?.location?.pathname?.includes("medensure")) {
        dispatch({
          type: "SAVE_CLIENT",
          payload: urlSearchParams.get("client") || "",
        })
      }
    }
  }, [])

  let company = ""
  if (isBrowser()) company = sessionStorage.getItem("company")

  if (state?.client || company) {
    switch (state?.client || company) {
      case "Accenture":
        notificationEmail = "accenture.med@medgrocer.com"
        break
      case "Maxicare":
        notificationEmail = "maxicare.med@medgrocer.com"
        break
      case "Foundever":
        notificationEmail = "foundever.med@medgrocer.com"
        break
      default:
        notificationEmail = "order@medgrocer.com"
        break
    }
  }

  if (!!banner && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div
          className="has-text-centered"
          dangerouslySetInnerHTML={{
            __html: banner
              ?.map((elem) => elem?.replace("<p>", "<p class='is-size-6'>"))
              ?.join(""),
          }}
        />
      </div>
    )

  return null
}

export default Notification
