/*
 * MedGrocer logo only
 */
import React, { useState, useContext, Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faShoppingCart,
  faTimes,
  faUser,
  faCapsules,
  faEnvelope,
  faBriefcase,
  faQuestion,
  faFileAlt,
  faShieldAlt,
  faSyringe,
} from "@fortawesome/free-solid-svg-icons"

import { AppContext } from "../../context/AppContext"

import styles from "./utils/layout.module.scss"

const Navbar = ({ path }) => {
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long-white.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircle: file(
        relativePath: { eq: "logos/medgrocer-square-white.png" }
      ) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const navbarItems = [
    {
      name: "Order",
      icon: faCapsules,
      link: "/epharmacy/order",
      isHiddenInDesktop: false,
      // type: "dropdown",

      // links: [
      //   {
      //     name: "Medicines",
      //     icon: faCapsules,
      //     link: "/epharmacy/order",
      //   },
      //   {
      //     name: "Vaccines",
      //     icon: faSyringe,
      //     href: `${process.env.GATSBY_MGHEALTH_BASE_LINK}/vax`,
      //   },
      // ],
    },

    {
      name: "Corporate",
      link: "/corporate",
      icon: faBriefcase,
      isHiddenInDesktop: false,
    },
    {
      name: "Careers",
      link: "/careers",
      icon: faUser,
      isHiddenInDesktop: false,
    },
    {
      name: "Contact Us",
      link: "/help-center#contact-us",
      icon: faEnvelope,
      isHiddenInDesktop: false,
    },
    {
      name: "Help Center",
      link: "/help-center",
      icon: faQuestion,
      isHiddenInDesktop: true,
    },
    {
      name: "Terms and Conditions",
      link: "/terms-and-conditions",
      icon: faFileAlt,
      isHiddenInDesktop: true,
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy",
      icon: faShieldAlt,
      isHiddenInDesktop: true,
    },
  ]

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  const { state, dispatch } = useContext(AppContext)
  const [isMenuActive, setMenuActive] = useState(false)

  const handleMenuActive = () => setMenuActive(!isMenuActive)
  const showCart = () =>
    dispatch({
      type: "SHOW_CART",
    })

  const medensurePath = path.includes("medensure")

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-light is-primary">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Link to={!!medensurePath ? "/medensure" : "/"}>
            <Img fixed={logoLong} className="is-hidden-mobile" />
            <Img fixed={logoCircle} className="is-hidden-tablet" />
          </Link>
          <div className={styles["navbar__burgerContainer"]}>
            <a
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="icon"
            >
              <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
            </a>
          </div>
        </div>
        <div
          className={classNames(
            "navbar-menu",
            styles["navbar__menu"],
            { [styles["mobile__navigation"]]: isMenuActive },
            {
              "is-active": isMenuActive,
            }
          )}
        >
          <div className="navbar-end mr-1">
            {(!!medensurePath ? [] : navbarItems).map((item) =>
              item.type === "dropdown" ? (
                <div className="navbar-item has-dropdown is-hoverable is-hidden-mobile">
                  <a className="navbar-link">{item.name}</a>

                  <div className="navbar-dropdown is-right">
                    {item.links.map((link) =>
                      !!link.href ? (
                        <a
                          className="navbar-item"
                          href={link.href}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <FontAwesomeIcon
                            icon={link.icon}
                            className="icon mr-1 has-text-primary"
                          />
                          {link.name}
                        </a>
                      ) : (
                        <Link className="navbar-item" to={link.link}>
                          <FontAwesomeIcon
                            icon={link.icon}
                            className="icon mr-1 has-text-primary"
                          />
                          {link.name}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              ) : !!item.href ? (
                <a
                  className={classNames(
                    "navbar-item p-1",
                    styles["navbar__menuItem"],
                    {
                      "is-hidden-desktop": item.isHiddenInDesktop,
                    }
                  )}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="icon mr-1 has-text-primary"
                  />
                  {item.name}
                </a>
              ) : (
                <Link
                  to={item.link}
                  className={item.class}
                  onClick={() => setMenuActive(false)}
                >
                  <span
                    className={classNames(
                      "navbar-item p-1",
                      styles["navbar__menuItem"],
                      { "is-hidden-desktop": item.isHiddenInDesktop }
                    )}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="is-hidden-desktop icon mr-1 has-text-primary"
                    />
                    {item.name}
                  </span>
                </Link>
              )
            )}
          </div>
        </div>
      </nav>
      <section
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
